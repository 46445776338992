import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "title": "Add Reason Code", "has-deactivate-button": false, "has-last-modified": false } }, [_c("text-input", { key: "reasonCode", attrs: { "label": "Reason Code", "placeholder": "Type Reason Code", "span": 24, "rules": "required" } })], 1);
};
var staticRenderFns$1 = [];
var EditReasonCode_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const __vue2_script$1 = {
  name: "EditReasonCode",
  inject: ["crud", "facility", "area"],
  created() {
    this.storeValue("facilityId", this.facility.value);
    this.storeValue("areaId", this.area.value);
  },
  methods: {
    storeValue(resourceKey, value) {
      this.crud.updateEntity("editForm", { resourceKey, value });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditReasonCode = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "line-performance.ctpreasons", "api-url": _vm.apiUrl, "redirect-route": "/line-performance/reason-code" } }, [_c("edit-reason-code")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { EditReasonCode },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
